import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
import componentsRouter from "./modules/components";
import chartsRouter from "./modules/charts";
import tableRouter from "./modules/table";
import nestedRouter from "./modules/nested";
import { checkOS } from '@/utils/system';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/mobile',
  component: function component() {
    return import('@/views/wechat');
  },
  hidden: true,
  children: [{
    path: 'inspect/outTaskList',
    component: function component() {
      return import('@/views/wechat/inspect/outTaskList');
    },
    name: 'outTaskList',
    meta: {
      title: 'OutTaskList',
      icon: 'people',
      noCache: true
    }
  }, {
    path: 'inspect/viewDetails',
    component: function component() {
      return import('@/views/wechat/inspect/viewDetails');
    },
    name: 'ViewDetails',
    meta: {
      title: 'ViewDetails',
      icon: 'people',
      noCache: false
    }
  }, {
    path: 'inspect/rectificationReply',
    component: function component() {
      return import('@/views/wechat/inspect/rectificationReply');
    },
    name: 'rectificationReply',
    meta: {
      title: 'rectificationReply',
      icon: 'people',
      noCache: true
    }
  }, {
    path: 'inspect/taskDetailOut',
    component: function component() {
      return import('@/views/wechat/inspect/taskDetailOut');
    },
    name: 'taskDetailOut',
    meta: {
      title: 'taskDetailOut',
      icon: 'people',
      noCache: false
    }
  }, {
    path: 'inspect/noRectification',
    component: function component() {
      return import('@/views/wechat/inspect/noRectification');
    },
    name: 'noRectification',
    meta: {
      title: 'noRectification',
      icon: 'people',
      noCache: false
    }
  }, {
    path: 'inspect/rectificationReview',
    component: function component() {
      return import('@/views/wechat/inspect/rectificationReview');
    },
    name: 'rectificationReview',
    meta: {
      title: 'rectificationReview',
      icon: 'people',
      noCache: true
    }
  }, {
    path: 'parking/open-visitor',
    component: function component() {
      return import('@/views/wechat/parking/open-visitor');
    },
    name: 'parkingOpenVisitor',
    meta: {
      title: 'parkingOpenVisitor',
      icon: 'car',
      noCache: true
    }
  }, {
    path: 'parking/open-home',
    component: function component() {
      return import('@/views/wechat/parking/open-visitor/home');
    },
    name: 'parkingOpenHome',
    meta: {
      title: 'parkingOpenVisitor',
      icon: 'car',
      noCache: true
    }
  }

  // {
  //   path: 'energyMonitoring/index',
  //   component: () => import('@/views/wechat/energyMonitoring/index'),
  //   name: 'energyMonitoring',
  //   meta: { title: 'energyMonitoring', icon: 'car', noCache: true }
  // }
  ]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/ssoLogin',
  component: function component() {
    return import('@/views/login/sso-login');
  },
  hidden: true
}, {
  path: '/authLogin',
  component: function component() {
    return import('@/views/login/auth-login');
  },
  hidden: true
}, {
  path: '/top-scientists',
  component: function component() {
    return import('@/views/top-scientists/index');
  },
  hidden: true
}, {
  path: '/top-scientists-edit',
  component: function component() {
    return import('@/views/wechat/top-scientists/index');
  },
  hidden: true
}, {
  path: '/servicetest',
  component: function component() {
    return import('@/views/servicetest/index');
  },
  hidden: true
}, {
  path: '/sewageLogin',
  component: function component() {
    return import('@/views/wslogin/HsLogin');
  },
  name: 'sewageLogin',
  hidden: true
}, {
  path: '/monitorRever',
  component: function component() {
    return import('@/views/sewage/monitor/monitorRever');
  },
  name: 'monitorRever',
  meta: {
    title: 'monitorRever',
    icon: 'car',
    noCache: true
  },
  hidden: true
}, {
  path: '/monitorSupervision',
  component: function component() {
    return import('@/views/sewage/monitor/monitorSupervision');
  },
  name: 'monitorSupervision',
  meta: {
    title: 'monitorSupervision',
    icon: 'car',
    noCache: true
  },
  hidden: true
}, {
  path: '/newMonitorJADYC',
  component: function component() {
    return import('@/views/sewage/jinan_dayuecheng/newMonitorJADYC');
  },
  name: 'newMonitorJADYC',
  meta: {
    title: 'newMonitorJADYC',
    icon: 'car',
    noCache: true
  },
  hidden: true
}, {
  path: '/monitorJADYC',
  component: function component() {
    return import('@/views/sewage/jinan_dayuecheng/monitorJADYC');
  },
  name: 'monitorJADYC',
  meta: {
    title: 'monitorJADYC',
    icon: 'car',
    noCache: true
  },
  hidden: true
}, {
  path: '/videoWall',
  component: function component() {
    return import('@/views/sewage/jinan_dayuecheng/videoWall');
  },
  name: 'videoWall',
  meta: {
    title: 'videoWall',
    icon: 'car',
    noCache: true
  },
  hidden: true
}, {
  path: '/safetyProduction',
  component: function component() {
    return import('@/views/sewage/jinan_dayuecheng/safetyProduction');
  },
  name: 'safetyProduction',
  meta: {
    title: 'safetyProduction',
    icon: 'car',
    noCache: true
  },
  hidden: true
},
// 静安大悦城-北楼-路由
{
  path: '/newMonitorJADYCNorth',
  component: function component() {
    return import('@/views/sewage/jinan_dayuecheng/newMonitorJADYCNorth');
  },
  name: 'newMonitorJADYCNorth',
  meta: {
    title: 'newMonitorJADYCNorth',
    icon: 'car',
    noCache: true
  },
  hidden: true
}, {
  path: '/monitorJADYCNorth',
  component: function component() {
    return import('@/views/sewage/jinan_dayuecheng/monitorJADYCNorth');
  },
  name: 'monitorJADYCNorth',
  meta: {
    title: 'monitorJADYCNorth',
    icon: 'car',
    noCache: true
  },
  hidden: true
}, {
  path: '/videoWallNorth',
  component: function component() {
    return import('@/views/sewage/jinan_dayuecheng/videoWallNorth');
  },
  name: 'videoWallNorth',
  meta: {
    title: 'videoWallNorth',
    icon: 'car',
    noCache: true
  },
  hidden: true
}, {
  path: '/safetyProductionNorth',
  component: function component() {
    return import('@/views/sewage/jinan_dayuecheng/safetyProductionNorth');
  },
  name: 'safetyProductionNorth',
  meta: {
    title: 'safetyProductionNorth',
    icon: 'car',
    noCache: true
  },
  hidden: true
}, {
  path: '/codeAdministration',
  component: function component() {
    return import('@/views/sewage/monitor/codeAdministration');
  },
  name: 'codeAdministration',
  meta: {
    title: 'codeAdministration',
    icon: 'car',
    noCache: true
  },
  hidden: true
}, {
  path: '/lsds/index',
  component: function component() {
    return import('@/views/lsds/index');
  },
  hidden: true
}, {
  path: '/lsds/shop-area',
  component: function component() {
    return import('@/views/lsds/shop-area');
  },
  hidden: true
}, {
  path: '/bind',
  component: function component() {
    return import('@/views/wechat/bind/index.vue');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/conference',
  component: function component() {
    return import('@/views/meetingRoom/conference');
  },
  hidden: true
}, {
  path: '/conferenceRoom',
  component: function component() {
    return import('@/views/meetingRoom/conferenceRoom');
  },
  hidden: true
}, {
  path: '*',
  component: function component() {
    return import('@/views/error-page/404');
  },
  // component: Layout,
  // // component: () => import('@/views/error-page/404'),
  // redirect: '/dashboard',
  // children: [
  //   {
  //     path: 'index',
  //     component: () => import('@/views/dashboard/index'),
  //     name: 'Dashboard',
  //     meta: { title: 'dashboard', icon: 'home', affix: true, noCache: false }
  //   }
  // ],
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/profile/index');
    },
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
}, {
  path: '/stop-service',
  component: Layout,
  hidden: true,
  children: [{
    path: 'stop',
    component: function component() {
      return import('@/views/stopService/index');
    },
    name: 'stopPart',
    meta: {
      title: 'stopPart',
      icon: 'org',
      noCache: true
    }
  }, {
    path: 'partone',
    component: function component() {
      return import('@/views/stopService/partoneService/index');
    },
    name: 'stopPartOne',
    meta: {
      title: 'stopPartOne',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: 'parttwo',
    component: function component() {
      return import('@/views/stopService/parttwoService/index');
    },
    name: 'stopPartTwo',
    meta: {
      title: 'stopPartTwo',
      icon: 'dashboard',
      noCache: true
    }
  }]
}];
export var mobileRoutes = [{
  path: '/',
  redirect: '/mobile/dashboard',
  component: function component() {
    return import('@/mobileLayout');
  },
  hidden: true,
  children: [
  // 访客预约
  {
    path: '/mobile/dlgjtvisitor',
    component: function component() {
      return import('@/views/wechat/visitorReservation/index');
    },
    name: 'dlggtvisitor',
    meta: {
      title: 'dlggtvisitor',
      icon: 'org',
      noCache: true
    }
  }, {
    path: '/mobile/detail',
    component: function component() {
      return import('@/views/wechat/visitorReservation/components/detail');
    },
    name: 'detail',
    meta: {
      title: 'detail',
      icon: 'org',
      noCache: true
    }
  }, {
    path: '/mobile/success',
    component: function component() {
      return import('@/views/wechat/visitorReservation/components/success');
    },
    name: 'success',
    meta: {
      title: 'success',
      icon: 'org',
      noCache: true
    }
  }, {
    path: '/mobile/applyInfo',
    component: function component() {
      return import('@/views/wechat/visitorReservation/components/applyInfo');
    },
    name: 'applyInfo',
    meta: {
      title: 'applyInfo',
      icon: 'org',
      noCache: true
    }
  }, {
    path: '/mobile/dlgexpregforzyy',
    component: function component() {
      return import('@/views/wechat/eventRegistration/index');
    },
    name: 'dlgexpregforzyy',
    meta: {
      title: 'dlgexpdetailforzyy',
      icon: 'org',
      noCache: true
    }
  }, {
    path: '/mobile/dlgexplistforzyy',
    component: function component() {
      return import('@/views/wechat/eventRegistration/dlgexplistforzyy');
    },
    name: 'dlgexplistforzyy',
    meta: {
      title: 'dlgexpdetailforzyy',
      icon: 'org',
      noCache: true
    }
  }, {
    path: '/mobile/dlgexpjoinforzyy',
    component: function component() {
      return import('@/views/wechat/eventRegistration/dlgexpjoinforzyy');
    },
    name: 'dlgexpjoinforzyy',
    meta: {
      title: 'dlgexpdetailforzyy',
      icon: 'org',
      noCache: true
    }
  }, {
    path: '/mobile/dlgexpdetailforzyy',
    component: function component() {
      return import('@/views/wechat/eventRegistration/components/detail');
    },
    name: 'dlgexpdetailforzyy',
    meta: {
      title: 'dlgexpdetailforzyy',
      icon: 'org',
      noCache: true
    }
  }, {
    path: 'mobile/dashboard',
    component: function component() {
      return import('@/views/wechat/dashboard/index');
    },
    name: 'MobileDashboard',
    meta: {
      title: 'dashboard',
      icon: 'home',
      noCache: true
    }
  }, {
    path: 'mobile/dhDashboard',
    component: function component() {
      return import('@/views/wechat/dashboard/dhIndex');
    },
    name: 'MobileDashboard',
    meta: {
      title: 'dashboard',
      icon: 'home',
      noCache: true
    }
  }, {
    path: 'mobile/message',
    component: function component() {
      return import('@/views/wechat/dashboard/message');
    },
    name: 'message',
    meta: {
      title: 'message',
      icon: 'org'
    }
  }, {
    path: 'mobile/messageDetail',
    component: function component() {
      return import('@/views/wechat/dashboard/messageDetail');
    },
    name: 'messageDetail',
    meta: {
      title: 'messageDetail',
      icon: 'org'
    }
  }, {
    path: 'mobile/profile',
    component: function component() {
      return import('@/views/wechat/profile/index');
    },
    name: 'MobileProfile',
    meta: {
      title: 'profile',
      icon: 'people',
      noCache: true
    }
  }, {
    path: 'mobile/dhProfile',
    component: function component() {
      return import('@/views/wechat/profile/dhIndex');
    },
    name: 'MobileProfile',
    meta: {
      title: 'profile',
      icon: 'people',
      noCache: true
    }
  }, {
    path: 'mobile/updPwd',
    component: function component() {
      return import('@/views/wechat/updPwd/index');
    },
    name: 'MobileUpdPwd',
    meta: {
      title: 'updPwd',
      icon: 'people',
      noCache: true
    }
  }, {
    path: 'mobile/code/index',
    component: function component() {
      return import('@/views/wechat/code/index');
    },
    name: 'MobileCodeIndex',
    meta: {
      title: 'myCode',
      icon: 'people',
      noCache: true
    }
  }, {
    path: 'mobile/code/face',
    component: function component() {
      return import('@/views/wechat/code/face');
    },
    name: 'MobileCodeFace',
    meta: {
      title: 'face',
      icon: 'people',
      noCache: true
    }
  }, {
    path: 'mobile/ls-code/index',
    component: function component() {
      return import('@/views/wechat/lsCode/index');
    },
    // name: 'MobileCodeIndex',
    meta: {
      title: 'myCode',
      icon: 'people',
      noCache: true
    }
  }, {
    path: 'mobile/ls-code/face',
    component: function component() {
      return import('@/views/wechat/lsCode/face');
    },
    // name: 'MobileCodeFace',
    meta: {
      title: 'face',
      icon: 'people',
      noCache: true
    }
  }, {
    path: '/sewage/sewageIndex',
    component: function component() {
      return import('@/views/wechat/sewage/sewageIndex');
    },
    name: 'sewageIndex',
    meta: {
      title: 'sewageIndex',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/sewage/sewageDetail',
    component: function component() {
      return import('@/views/wechat/sewage/sewageDetail');
    },
    name: 'sewageDetail',
    meta: {
      title: 'sewageDetail',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/sewageDataSetting',
    component: function component() {
      return import('@/views/wechat/sewage/sewageDataSetting');
    },
    name: 'sewageDataSetting',
    meta: {
      title: 'sewageDataSetting',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/quarantineLogin',
    component: function component() {
      return import('@/views/wslogin/quarantineLogin');
    },
    name: 'quarantineLogin',
    meta: {
      title: 'quarantineLogin',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/quarantine',
    component: function component() {
      return import('@/views/wechat/quarantine/index');
    },
    name: 'quarantine',
    meta: {
      title: 'quarantine',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/quarantine/quarantineArea',
    component: function component() {
      return import('@/views/wechat/quarantine/quarantineArea');
    },
    name: 'quarantineArea',
    meta: {
      title: 'quarantineArea',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/quarantine/warehouse',
    component: function component() {
      return import('@/views/wechat/quarantine/warehouse');
    },
    name: 'warehouse',
    meta: {
      title: 'quarantineArea',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/quarantine/quarantineDetail',
    component: function component() {
      return import('@/views/wechat/quarantine/quarantineDetail');
    },
    name: 'quarantineDetail',
    meta: {
      title: 'quarantineDetail',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/quarantine/monitorCode',
    component: function component() {
      return import('@/views/wechat/quarantine/monitorCode');
    },
    name: 'monitorCode',
    meta: {
      title: 'monitorCode',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/quarantine/quarantineHistory',
    component: function component() {
      return import('@/views/wechat/quarantine/quarantineHistory');
    },
    name: 'quarantineHistory',
    meta: {
      title: 'quarantineHistory',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/quarantine/quarantineAll',
    component: function component() {
      return import('@/views/wechat/quarantine/quarantineAll');
    },
    name: 'quarantineAll',
    meta: {
      title: 'quarantineAll',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/quarantine/quarantineExcel',
    component: function component() {
      return import('@/views/wechat/quarantine/quarantineExcel');
    },
    name: 'quarantineExcel',
    meta: {
      title: 'quarantineExcel',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/quarantine/quarantineInfo',
    component: function component() {
      return import('@/views/wechat/quarantine/quarantineInfo');
    },
    name: 'quarantineInfo',
    meta: {
      title: 'quarantineInfo',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/quarantine/quarantineUser',
    component: function component() {
      return import('@/views/wechat/quarantine/quarantineUser');
    },
    name: 'quarantineUser',
    meta: {
      title: 'quarantineUser',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/quarantine/quarantineReport',
    component: function component() {
      return import('@/views/wechat/quarantine/quarantineReport');
    },
    name: 'quarantineReport',
    meta: {
      title: 'quarantineReport',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/energyControl/index',
    component: function component() {
      return import('@/views/wechat/energyControl/index');
    },
    name: 'EnergyControl',
    meta: {
      title: '',
      icon: 'dashboard',
      noCache: false
    }
  }, {
    path: '/mobile/zone/index',
    component: function component() {
      return import('@/views/wechat/zone/index');
    },
    name: 'zoneIndex',
    meta: {
      title: 'zoneIndex',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/zone/activity',
    component: function component() {
      return import('@/views/wechat/zone/activity/index');
    },
    name: 'zoneActivity',
    meta: {
      title: 'zoneActivity',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/zone/activity/create',
    component: function component() {
      return import('@/views/wechat/zone/activity/create');
    },
    name: 'zoneActivityCreate',
    meta: {
      title: 'zoneActivityCreate',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/zone/register',
    component: function component() {
      return import('@/views/wechat/zone/register/index');
    },
    name: 'zoneRegister',
    meta: {
      title: 'zoneRegister',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/zone/register/create',
    component: function component() {
      return import('@/views/wechat/zone/register/create');
    },
    name: 'zoneRegisterCreate',
    meta: {
      title: 'zoneRegisterCreate',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/zone/register/detail',
    component: function component() {
      return import('@/views/wechat/zone/register/detail');
    },
    name: 'zoneRegisterDetail',
    meta: {
      title: 'zoneRegisterDetail',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/zone/activity/detail',
    component: function component() {
      return import('@/views/wechat/zone/activity/detail');
    },
    name: 'zoneActivityDetail',
    meta: {
      title: 'zoneActivityDetail',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/zone/model',
    component: function component() {
      return import('@/views/wechat/zone/model/index');
    },
    name: 'zoneModel',
    meta: {
      title: 'zoneModel',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/zone/agenda',
    component: function component() {
      return import('@/views/wechat/zone/agenda/index');
    },
    name: 'zoneAgenda',
    meta: {
      title: 'zoneAgenda',
      icon: 'dashboard',
      noCache: true
    }
  },
  // {
  //   path: '/mobile/zone/agenda/edit',
  //   component: () => import('@/views/wechat/zone/agenda/edit'),
  //   name: 'ZoneAgendaEdit',
  //   meta: { title: 'ZoneAgendaEdit', icon: 'dashboard', noCache: true }
  // },
  {
    path: '/mobile/annals/index',
    component: function component() {
      return import('@/views/wechat/annals/index');
    },
    name: 'index',
    meta: {
      title: 'index',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/annals/startIndex',
    component: function component() {
      return import('@/views/wechat/annals/startIndex');
    },
    name: 'startIndex',
    meta: {
      title: 'startIndex',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: '/mobile/meetingRoom/invitationCode',
    component: function component() {
      return import('@/views/wechat/meetingRoom/appointInfo/invitationCode');
    },
    meta: {
      title: '会展邀请码',
      affix: true,
      noCache: true
    }
  }, {
    path: '/mobile/meetingRoom/appointInfo',
    component: function component() {
      return import('@/views/wechat/meetingRoom/appointInfo/index');
    },
    meta: {
      title: '会展区分',
      affix: true,
      noCache: true
    }
  }, {
    path: '/mobile/appointInfo/registered',
    component: function component() {
      return import('@/views/wechat/meetingRoom/appointInfo/registered');
    },
    meta: {
      title: '会展已注册',
      affix: true,
      noCache: true
    }
  }, {
    path: '/mobile/appointInfo/unregistered',
    component: function component() {
      return import('@/views/wechat/meetingRoom/appointInfo/unregistered');
    },
    meta: {
      title: '会展未注册',
      affix: true,
      noCache: true
    }
  }, {
    path: '/mobile/appointInfo/exhibitionIndex',
    component: function component() {
      return import('@/views/wechat/meetingRoom/appointInfo/exhibitionIndex');
    },
    meta: {
      title: '会展信息',
      affix: true,
      noCache: true
    }
  }, {
    path: '/mobile/appointment/detail',
    component: function component() {
      return import('@/views/wechat/meetingRoom/appointment/detail');
    },
    meta: {
      title: '会议室详情',
      affix: true,
      noCache: false
    }
  }, {
    path: '/mobile/visitor/createRegistrationCode',
    component: function component() {
      return import('@/views/wechat/newVisitorSystem/createRegistrationCode');
    },
    name: 'createRegistrationCode',
    meta: {
      title: 'createRegistrationCode',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/visitor/visitorByVerificationCodeDetail',
    component: function component() {
      return import('@/views/wechat/newVisitorSystem/visitorByVerificationCodeDetail');
    },
    name: 'visitorByVerificationCodeDetail',
    meta: {
      title: 'visitorByVerificationCodeDetail',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/visitor/visitorRegistration',
    component: function component() {
      return import('@/views/wechat/newVisitorSystem/visitorRegistration');
    },
    name: 'visitorRegistration',
    meta: {
      title: 'visitorRegistration',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/VVCode',
    component: function component() {
      return import('@/views/wechat/newVisitorSystem/visitorByVerificationCode');
    },
    name: 'visitorByVerificationCode',
    meta: {
      title: 'visitorByVerificationCode',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/visitor/registrationCode',
    component: function component() {
      return import('@/views/wechat/newVisitorSystem/registrationCode');
    },
    name: 'registrationCode',
    meta: {
      title: 'registrationCode',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/visitor/visitorInformation',
    component: function component() {
      return import('@/views/wechat/newVisitorSystem/visitorInformation');
    },
    name: 'visitorInformation',
    meta: {
      title: 'visitorInformation',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/wordExpo',
    component: function component() {
      return import('@/views/wechat/wordExpo/index');
    },
    name: 'wordExpo',
    meta: {
      title: 'wordExpo',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/boothConstruction/login',
    component: function component() {
      return import('@/views/wechat/boothConstruction/login');
    },
    name: 'login',
    meta: {
      title: 'login',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/boothConstruction/outRectificationInformation',
    component: function component() {
      return import('@/views/wechat/boothConstruction/outRectificationInformation');
    },
    name: 'outRectificationInformation',
    meta: {
      title: 'outRectificationInformation',
      icon: 'car'
    }
  }, {
    path: '/mobile/boothConstruction/outRectificationNotice',
    component: function component() {
      return import('@/views/wechat/boothConstruction/outRectificationNotice');
    },
    name: 'outRectificationNotice',
    meta: {
      title: 'outRectificationNotice',
      icon: 'car'
    }
  }, {
    path: '/mobile/boothConstruction/outRectificationReply',
    component: function component() {
      return import('@/views/wechat/boothConstruction/outRectificationReply');
    },
    name: 'outRectificationReply',
    meta: {
      title: 'outRectificationReply',
      icon: 'car'
    }
  }, {
    path: '/mobile/homeCourtSetup/menuBar',
    component: function component() {
      return import('@/views/wechat/homeCourtSetup/index');
    },
    name: 'menuBar',
    meta: {
      title: 'menuBar',
      icon: 'car'
    }
  }, {
    path: '/mobile/homeCourtSetup/summarySheetList',
    component: function component() {
      return import('@/views/wechat/homeCourtSetup/summarySheetList');
    },
    name: 'summarySheetList',
    meta: {
      title: 'summarySheetList',
      icon: 'car'
    }
  }, {
    path: '/mobile/homeCourtSetup/rectificationSummary',
    component: function component() {
      return import('@/views/wechat/homeCourtSetup/rectificationSummary');
    },
    name: 'rectificationSummary',
    meta: {
      title: 'rectificationSummary',
      icon: 'car'
    }
  }, {
    path: '/mobile/homeCourtSetup/exhibitionInformation',
    component: function component() {
      return import('@/views/wechat/homeCourtSetup/exhibitionInformation');
    },
    name: 'exhibitionInformation',
    meta: {
      title: 'exhibitionInformation',
      icon: 'car'
    }
  }, {
    path: '/mobile/homeCourtSetup/viewAll',
    component: function component() {
      return import('@/views/wechat/homeCourtSetup/viewAll');
    },
    name: 'viewAll',
    meta: {
      title: 'viewAll',
      icon: 'car'
    }
  }, {
    path: '/mobile/homeCourtSetup/newExhibition',
    component: function component() {
      return import('@/views/wechat/homeCourtSetup/newExhibition');
    },
    name: 'newExhibition',
    meta: {
      title: 'newExhibition',
      icon: 'car'
    }
  }, {
    path: '/mobile/homeCourtSetup/rectificationAndPowerTransmission',
    component: function component() {
      return import('@/views/wechat/homeCourtSetup/rectificationAndPowerTransmission');
    },
    name: 'rectificationAndPowerTransmission',
    meta: {
      title: 'rectificationAndPowerTransmission',
      icon: 'car'
    }
  }, {
    path: '/mobile/homeCourtSetup/rectifyAndReformList',
    component: function component() {
      return import('@/views/wechat/homeCourtSetup/rectifyAndReformList');
    },
    name: 'rectifyAndReformList',
    meta: {
      title: 'rectifyAndReformList',
      icon: 'car'
    }
  }, {
    path: '/mobile/saas-window/index',
    component: function component() {
      return import('@/views/wechat/saas-window/index');
    },
    name: 'saasindex',
    meta: {
      title: 'saasindex',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/saas-window/modeManagement',
    component: function component() {
      return import('@/views/wechat/saas-window/modeManagement');
    },
    name: 'modeManagement',
    meta: {
      title: 'modeManagement',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/saas-window/newWindowPattern',
    component: function component() {
      return import('@/views/wechat/saas-window/newWindowPattern');
    },
    name: 'newWindowPattern',
    meta: {
      title: 'newWindowPattern',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/saas-window/operateWindow',
    component: function component() {
      return import('@/views/wechat/saas-window/operateWindow');
    },
    name: 'operateWindow',
    meta: {
      title: 'operateWindow',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/saas-window/windowList',
    component: function component() {
      return import('@/views/wechat/saas-window/windowList');
    },
    name: 'windowList',
    meta: {
      title: 'windowList',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/saas-window/cj-windows',
    component: function component() {
      return import('@/views/wechat/saas-window/cj-windows/index');
    },
    name: 'cj-windows',
    meta: {
      title: 'cj-windows',
      icon: 'car',
      noCache: true
    }
  }, {
    path: 'xg/parking-visitor',
    component: function component() {
      return import('@/views/wechat/xg/parking-visitor');
    },
    name: 'xgParkingVisitor',
    meta: {
      title: 'xgParkingVisitor',
      icon: 'car',
      noCache: true
    }
  }, {
    path: 'xg/approval',
    component: function component() {
      return import('@/views/wechat/xg/approval');
    },
    name: 'xgApproval',
    meta: {
      title: 'xgApproval',
      icon: 'car',
      noCache: true
    }
  }, {
    path: '/mobile/LSDS',
    component: function component() {
      return import('@/components/LsdsTabbar/index');
    },
    name: 'LSDS',
    meta: {
      title: 'LSDS',
      noCache: true
    },
    children: [{
      path: '/mobile/lsds/index',
      component: function component() {
        return import('@/views/wechat/lsds/index/index');
      },
      name: 'lsds',
      meta: {
        title: 'lsds',
        noCache: true
      }
    }, {
      path: '/mobile/lsds/energy',
      component: function component() {
        return import('@/views/wechat/lsds/energy/index');
      },
      name: 'energy',
      meta: {
        title: 'energy',
        noCache: true
      }
    }, {
      path: '/mobile/lsds/permission',
      component: function component() {
        return import('@/views/wechat/lsds/permission/index');
      },
      name: 'permission',
      meta: {
        title: 'permission',
        noCache: true
      }
    }, {
      path: '/mobile/lsds/timing',
      component: function component() {
        return import('@/views/wechat/lsds/timing/index');
      },
      name: 'timing',
      meta: {
        title: 'timing',
        noCache: true
      }
    }]
  }, {
    path: '/mobile/lsds/regulate',
    component: function component() {
      return import('@/views/wechat/lsds/index/regulate/index');
    },
    name: 'permission',
    meta: {
      title: 'permission',
      noCache: true
    }
  }]
}];
export var pcRoutes = [{
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'home',
      affix: true,
      noCache: false
    }
  }]
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [{
  path: '/icon',
  component: Layout,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/icons/index');
    },
    name: 'Icons',
    meta: {
      title: 'icons',
      icon: 'icon',
      noCache: true
    }
  }]
}, /** when your routing map is too long, you can split it into small modules **/
componentsRouter, chartsRouter, nestedRouter, tableRouter, {
  path: '/example',
  component: Layout,
  redirect: '/example/list',
  name: 'Example',
  meta: {
    title: 'example',
    icon: 'example'
  },
  children: [{
    path: 'create',
    component: function component() {
      return import('@/views/example/create');
    },
    name: 'CreateArticle',
    meta: {
      title: 'createArticle',
      icon: 'edit'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: function component() {
      return import('@/views/example/edit');
    },
    name: 'EditArticle',
    meta: {
      title: 'editArticle',
      noCache: true,
      activeMenu: '/example/list'
    },
    hidden: true
  }, {
    path: 'list',
    component: function component() {
      return import('@/views/example/list');
    },
    name: 'ArticleList',
    meta: {
      title: 'articleList',
      icon: 'list'
    }
  }]
}, {
  path: '/tab',
  component: Layout,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/tab/index');
    },
    name: 'Tab',
    meta: {
      title: 'tab',
      icon: 'tab'
    }
  }]
}, {
  path: '/error',
  component: Layout,
  redirect: 'noRedirect',
  name: 'ErrorPages',
  meta: {
    title: 'errorPages',
    icon: '404'
  },
  children: [{
    path: '401',
    component: function component() {
      return import('@/views/error-page/401');
    },
    name: 'Page401',
    meta: {
      title: 'page401',
      noCache: true
    }
  }, {
    path: '404',
    component: function component() {
      return import('@/views/error-page/404');
    },
    name: 'Page404',
    meta: {
      title: 'page404',
      noCache: true
    }
  }]
}, {
  path: '/error-log',
  component: Layout,
  children: [{
    path: 'log',
    component: function component() {
      return import('@/views/error-log/index');
    },
    name: 'ErrorLog',
    meta: {
      title: 'errorLog',
      icon: 'bug'
    }
  }]
}, {
  path: '/excel',
  component: Layout,
  redirect: '/excel/export-excel',
  name: 'Excel',
  meta: {
    title: 'excel',
    icon: 'excel'
  },
  children: [{
    path: 'export-excel',
    component: function component() {
      return import('@/views/excel/export-excel');
    },
    name: 'ExportExcel',
    meta: {
      title: 'exportExcel'
    }
  }, {
    path: 'export-selected-excel',
    component: function component() {
      return import('@/views/excel/select-excel');
    },
    name: 'SelectExcel',
    meta: {
      title: 'selectExcel'
    }
  }, {
    path: 'export-merge-header',
    component: function component() {
      return import('@/views/excel/merge-header');
    },
    name: 'MergeHeader',
    meta: {
      title: 'mergeHeader'
    }
  }, {
    path: 'upload-excel',
    component: function component() {
      return import('@/views/excel/upload-excel');
    },
    name: 'UploadExcel',
    meta: {
      title: 'uploadExcel'
    }
  }]
}, {
  path: '/zip',
  component: Layout,
  redirect: '/zip/download',
  alwaysShow: true,
  name: 'Zip',
  meta: {
    title: 'zip',
    icon: 'zip'
  },
  children: [{
    path: 'download',
    component: function component() {
      return import('@/views/zip/index');
    },
    name: 'ExportZip',
    meta: {
      title: 'exportZip'
    }
  }]
}, {
  path: '/pdf',
  component: Layout,
  redirect: '/pdf/index',
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/pdf/index');
    },
    name: 'PDF',
    meta: {
      title: 'pdf',
      icon: 'pdf'
    }
  }]
}, {
  path: '/pdf/download',
  component: function component() {
    return import('@/views/pdf/download');
  },
  hidden: true
}, {
  path: '/theme',
  component: Layout,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/theme/index');
    },
    name: 'Theme',
    meta: {
      title: 'theme',
      icon: 'theme'
    }
  }]
}, {
  path: '/clipboard',
  component: Layout,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/clipboard/index');
    },
    name: 'ClipboardDemo',
    meta: {
      title: 'clipboardDemo',
      icon: 'clipboard'
    }
  }]
}, {
  path: '/i18n',
  component: Layout,
  children: [{
    component: function component() {
      return import('@/views/i18n-demo/index');
    },
    name: 'I18n',
    meta: {
      title: 'i18n',
      icon: 'international'
    }
  }]
}, {
  path: 'external-link',
  component: Layout,
  children: [{
    path: 'https://github.com/PanJiaChen/vue-element-admin',
    meta: {
      title: 'externalLink',
      icon: 'link'
    }
  }]
},
// 404 page must be placed at the end !!!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}, {
  path: '/test',
  component: Layout,
  redirect: 'https://mp.weixin.qq.com/s/HW98Vzv77qf27daZaWdryQ'
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior(to, from, savePosition) {
      if (savePosition) {
        return savePosition;
      } else {
        return {
          y: 0
        };
      }
    },
    mode: 'history',
    routes: constantRoutes.concat(checkOS() === 'pc' ? pcRoutes : mobileRoutes)
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;